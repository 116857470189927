import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { WS_URL } from '../config/websocket';
import { signOut } from 'firebase/auth';
import { Upload, MessageSquare, Plus, File, Trash2, Menu, X, Pencil, LogOut } from 'lucide-react';

const API_URL = 'https://advanced-ai-chat.com/api';  // Changed from http to https and removed port 8000

const Chat = () => {
  const navigate = useNavigate();
  const [userId] = useState(auth.currentUser?.uid || 'default');
  const [chats, setChats] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [editingChatId, setEditingChatId] = useState(null);
  
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const textAreaRef = useRef(null);

  useEffect(() => {
    fetchUserChats();
  }, [userId]);

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMobileMenuOpen(false);
        setIsSidebarOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'inherit';
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = `${Math.min(scrollHeight, 200)}px`;
    }
  }, [message]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const fetchUserChats = async () => {
    try {
      const response = await fetch(`${API_URL}/chats/${userId}`);
      if (!response.ok) throw new Error('Failed to fetch chats');
      const data = await response.json();
      setChats(data);
      if (data.length > 0 && !activeChat) {
        setActiveChat(data[0].id);
        fetchChatHistory(data[0].id);
      }
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  };

  const fetchChatHistory = async (chatId) => {
    try {
      const response = await fetch(`${API_URL}/chats/${chatId}/history`);
      if (!response.ok) throw new Error('Failed to fetch chat history');
      const data = await response.json();
      
      const formattedHistory = data.flatMap(item => [
        {
          role: 'user',
          type: 'text',
          content: item.query,
          timestamp: item.timestamp
        },
        {
          role: 'assistant',
          type: 'text',
          content: item.response,
          timestamp: item.timestamp
        }
      ]);
      
      setChatHistory(formattedHistory);
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  };

  const updateChatTitle = async (chatId, newTitle) => {
    try {
      const response = await fetch(`${API_URL}/chats/${chatId}/title`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          title: newTitle
        })
      });

      if (!response.ok) throw new Error('Failed to update chat title');
      
      setChats(prevChats =>
        prevChats.map(chat =>
          chat.id === chatId
            ? { ...chat, title: newTitle }
            : chat
        )
      );
      setEditingChatId(null);
    } catch (error) {
      console.error('Error updating chat title:', error);
    }
  };

  const handleNewChat = async () => {
    const defaultTitle = `New Chat ${chats.length + 1}`;
    const title = prompt('Enter chat title:', defaultTitle) || defaultTitle;
    
    try {
      const response = await fetch(`${API_URL}/chats`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          title: title
        })
      });
      
      if (!response.ok) throw new Error('Failed to create chat');
      const newChat = await response.json();
      
      setChats([newChat, ...chats]);
      setActiveChat(newChat.id);
      setChatHistory([]);
      setIsMobileMenuOpen(false);
    } catch (error) {
      console.error('Error creating chat:', error);
    }
  };

  const handleFileUpload = async (e) => {
    const files = Array.from(e.target.files);
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('user_id', userId);
    formData.append('chat_id', activeChat);

    try {
      const response = await fetch(`${API_URL}/chat/upload`, {
        method: 'POST',
        body: formData
      });

      if (!response.ok) throw new Error('Failed to upload file');
      const data = await response.json();
      
      const fileMessage = {
        role: 'user',
        type: 'file',
        content: data.filename,
        timestamp: data.timestamp || new Date().toLocaleTimeString()
      };
      setChatHistory(prev => [...prev, fileMessage]);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const deleteChat = async (chatId) => {
    try {
      const response = await fetch(`${API_URL}/chats/${chatId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: userId })
      });
      
      if (!response.ok) throw new Error('Failed to delete chat');
      setChats(chats.filter(chat => chat.id !== chatId));
      if (activeChat === chatId) {
        const remainingChats = chats.filter(chat => chat.id !== chatId);
        if (remainingChats.length > 0) {
          setActiveChat(remainingChats[0].id);
          fetchChatHistory(remainingChats[0].id);
        } else {
          setActiveChat(null);
          setChatHistory([]);
        }
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  const clearChat = async () => {
    if (!activeChat) return;
    
    try {
      const response = await fetch(`${API_URL}/chats/${activeChat}/clear`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: userId })
      });

      if (!response.ok) throw new Error('Failed to clear chat');
      setChatHistory([]);
      setChats(prevChats => 
        prevChats.map(chat => 
          chat.id === activeChat 
            ? { ...chat, last_message: null, timestamp: new Date().toLocaleTimeString() }
            : chat
        )
      );
    } catch (error) {
      console.error('Error clearing chat:', error);
    }
  };

  const sendMessage = async () => {
    if (!message.trim() || !activeChat) return;
    
    const userMessage = {
      role: 'user',
      type: 'text',
      content: message,
      timestamp: new Date().toLocaleTimeString()
    };
    
    setChatHistory(prev => [...prev, userMessage]);
    setMessage('');
    setIsLoading(true);

    try {
      const response = await fetch(`${API_URL}/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          chat_id: activeChat,
          message: message
        })
      });

      if (!response.ok) throw new Error('Failed to send message');
      
      const data = await response.json();
      const assistantMessage = {
        role: 'assistant',
        type: 'text',
        content: data.response,
        timestamp: data.timestamp || new Date().toLocaleTimeString()
      };
      setChatHistory(prev => [...prev, assistantMessage]);
      
      setChats(prevChats => 
        prevChats.map(chat => 
          chat.id === activeChat 
            ? { ...chat, last_message: message, timestamp: data.timestamp }
            : chat
        )
      );
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = {
        role: 'system',
        type: 'text',
        content: 'Unable to connect to the assistant. Please try again.',
        timestamp: new Date().toLocaleTimeString()
      };
      setChatHistory(prev => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
      if (textAreaRef.current) {
        textAreaRef.current.style.height = 'inherit';
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const Sidebar = ({ isMobile = false }) => (
    <div className={`${
      isMobile 
        ? 'fixed inset-0 z-50 bg-gray-900' 
        : `${isSidebarOpen ? 'w-64' : 'w-0'} hidden md:block bg-gray-900`
    } text-white transition-all duration-300 overflow-hidden`}>
      {isMobile && (
        <div className="flex justify-end p-4">
          <button 
            onClick={() => setIsMobileMenuOpen(false)}
            className="text-white hover:text-gray-300"
          >
            <X size={24} />
          </button>
        </div>
      )}
      <div className="p-4">
        <button 
          onClick={handleNewChat}
          className="w-full flex items-center justify-center gap-2 px-4 py-3 bg-gray-800 hover:bg-gray-700 rounded-lg transition-colors"
        >
          <Plus size={20} />
          New Chat
        </button>
      </div>
      
      <div className="overflow-y-auto h-[calc(100vh-5rem)]">
        {chats.map(chat => (
          <div
            key={chat.id}
            onClick={() => {
              if (editingChatId !== chat.id) {
                setActiveChat(chat.id);
                fetchChatHistory(chat.id);
                setIsMobileMenuOpen(false);
              }
            }}
            className={`flex items-center gap-3 px-4 py-3 cursor-pointer transition-colors group ${
              activeChat === chat.id ? 'bg-gray-800' : 'hover:bg-gray-800'
            }`}
          >
            <MessageSquare size={18} />
            <div className="flex-1 min-w-0">
              {editingChatId === chat.id ? (
                <input
                  type="text"
                  defaultValue={chat.title}
                  autoFocus
                  onBlur={(e) => updateChatTitle(chat.id, e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      updateChatTitle(chat.id, e.target.value);
                    } else if (e.key === 'Escape') {
                      setEditingChatId(null);
                    }
                  }}
                  onClick={(e) => e.stopPropagation()}
                  className="bg-gray-700 text-white text-sm px-2 py-1 rounded w-full outline-none"
                />
              ) : (
                <>
                  <div 
                    className="text-sm truncate flex items-center gap-2"
                    onDoubleClick={(e) => {
                      e.stopPropagation();
                      setEditingChatId(chat.id);
                    }}
                  >
                    {chat.title}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingChatId(chat.id);
                      }}
                      className="opacity-0 group-hover:opacity-100 hover:text-blue-400"
                    >
                      <Pencil size={12} />
                    </button>
                  </div>
                  {chat.last_message && (
                    <div className="text-xs text-gray-400 truncate">{chat.last_message}</div>
                  )}
                  <div className="text-xs text-gray-400">{chat.timestamp}</div>
                </>
              )}
            </div>
            <Trash2 
              size={16} 
              className="opacity-0 group-hover:opacity-100 text-gray-400 hover:text-red-400"
              onClick={(e) => {
                e.stopPropagation();
                deleteChat(chat.id);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="flex h-screen bg-gray-100">
      {isMobileMenuOpen && <Sidebar isMobile />}
      <Sidebar />

      <div className="flex-1 flex flex-col w-full">
        <div className="bg-white border-b border-gray-200 p-4 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <button 
              onClick={() => setIsMobileMenuOpen(true)}
              className="p-2 hover:bg-gray-100 rounded-lg md:hidden"
            >
              <Menu size={20} />
            </button>
            <button 
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="p-2 hover:bg-gray-100 rounded-lg hidden md:block"
            >
              <MessageSquare size={20} />
            </button>
          </div>
          <h1 className="text-xl font-semibold">AI Assistant</h1>
          <div className="flex items-center gap-2">
            <button
              onClick={clearChat}
              className="p-2 hover:bg-gray-100 rounded-lg text-gray-500"
              disabled={!activeChat}
            >
              Clear Chat
            </button>
            <button
              onClick={handleSignOut}
              className="p-2 hover:bg-gray-100 rounded-lg text-gray-500"
              title="Sign Out"
            >
              <LogOut size={20} />
            </button>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-2 sm:p-4 bg-white">
          {chatHistory.length === 0 && (
            <div className="flex flex-col items-center justify-center h-full text-gray-500 p-4 text-center">
              <MessageSquare size={48} className="mb-4" />
              <h2 className="text-xl sm:text-2xl font-semibold mb-2">Welcome to AI Assistant</h2>
              <p className="text-sm sm:text-base">Start a conversation or upload a file to begin.</p>
            </div>
          )}

          {chatHistory.map((msg, index) => (
            <div 
              key={index}
              className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'} mb-4`}
            >
              <div className={`max-w-[85%] sm:max-w-[75%] md:max-w-[70%] ${
                msg.role === 'user' ? 'bg-blue-600 text-white' : 'bg-gray-100'
              } rounded-2xl px-3 sm:px-4 py-2`}>
                {msg.type === 'file' ? (
                  <div className="flex items-center gap-2 text-sm sm:text-base">
                    <File size={20} />
                    <span>{msg.content}</span>
                  </div>
                ) : (
                  <div className="whitespace-pre-wrap text-sm sm:text-base">{msg.content}</div>
                )}
                <div className={`text-xs mt-1 ${msg.role === 'user' ? 'text-blue-200' : 'text-gray-500'}`}>
                  {msg.timestamp}
                </div>
              </div>
            </div>
          ))}

          {isLoading && (
            <div className="flex justify-start mb-4">
              <div className="bg-gray-100 rounded-2xl px-4 py-2">
                <div className="flex gap-2">
                  <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></div>
                  <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></div>
                  <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0.4s' }}></div>
                </div>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>

        <div className="bg-white border-t border-gray-200 p-2 sm:p-4">
          <div className="max-w-4xl mx-auto flex gap-2 sm:gap-4">
            <button 
              onClick={() => fileInputRef.current?.click()}
              className="p-2 hover:bg-gray-100 rounded-lg text-gray-500 transition-colors"
              disabled={!activeChat}
            >
              <Upload size={20} />
            </button>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileUpload}
              className="hidden"
              multiple
            />
            <div className="flex-1 flex items-end gap-2 bg-gray-100 rounded-lg px-3 sm:px-4 py-2">
              <textarea
                ref={textAreaRef}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={handleKeyPress}
                placeholder="Type a message..."
                className="flex-1 bg-transparent border-none outline-none resize-none min-h-[24px] max-h-[200px] overflow-y-auto leading-6 text-sm sm:text-base"
                style={{ height: 'inherit' }}
                disabled={!activeChat}
              />
              <button
                onClick={sendMessage}
                disabled={!message.trim() || isLoading || !activeChat}
                className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed flex-shrink-0"
              >
                <svg viewBox="0 0 24 24" className="w-5 h-5">
                  <path fill="currentColor" d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
