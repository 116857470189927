import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCLDdOfEc1qYO37Jn2KMPyjK7IpVxQQ-98",
    authDomain: "advanced-chat-ai.firebaseapp.com",
    projectId: "advanced-chat-ai",
    storageBucket: "advanced-chat-ai.firebasestorage.app",
    messagingSenderId: "555813427272",
    appId: "1:555813427272:web:5ac69b9542ffbc5646207d",
    measurementId: "G-DQVPNLPJH1"
  };
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export auth instance
const auth = getAuth(app);

export { auth };  // Named export
export default app;